import { HeadingSizes } from '@/constants/HeadingSizes';
import { Mode } from '@/constants/Mode';
import { TextAlignments } from '@/constants/TextAlignments';
import { TextMarginBottom } from '@/constants/TextMarginBottom';
import React, { useState } from 'react';
import { CTAType } from '@/constants/CTAType';
import { responsiveSrcGenerator } from 'utilities/responsiveSrcGenerator';
import { EyeBrow, IEyebrow } from './EyeBrow';
import styles from './GenericBanner.module.scss';
import { Rate } from './Rate';
import { ITitle, Title } from './Title';
import { IText } from './Text';
import { type IVideoModal, VideoModal } from './VideoModal';
import { TrustBox } from './TrustBox';
import { IButton, Button } from './Button';
import { FunnelButton, IFunnelButton } from './FunnelButton';
import { srcSetGenerator } from '../utilities/srcSetGenerator';

export interface IGenericBannerImage {
  url: string;
  width?: number;
  height?: number;
  title: string;
}

export interface IGenericBanner {
  ooRate?: string;
  invRate?: string;
  textBoxWidth?: number;
  eyebrow?: IEyebrow;
  title?: ITitle;
  text?: ITitle;
  image?: IGenericBannerImage;
  imageMobile?: IGenericBannerImage;
  mode: Mode | string;
  ratesVisible?: boolean;
  rateType?: string;
  ratesAlignmentMobile?: TextAlignments | string;
  ratesAlignmentDesktop?: TextAlignments | string;
  backgroundColor?: string;
  backgroundImageMobile?: IGenericBannerImage;
  backgroundImageDesktop?: IGenericBannerImage;
  backgroundSizeMobile?: string;
  backgroundRepeatMobile?: string;
  backgroundPositionMobile?: string;
  backgroundSizeDesktop?: string;
  backgroundRepeatDesktop?: string;
  backgroundPositionDesktop?: string;
  onProduct?: boolean;
  cta?: IButton;
  ctaFunnel?: IFunnelButton;
  ctaType?: CTAType | string;
  ctaVisible?: boolean;
  ctaAlignment: TextAlignments | string;
  backgroundDesktop?: string;
  backgroundMobile?: string;
  trustPilotVisible?: boolean;
  isVideo?: boolean;
  videoModal?: IVideoModal[];
  relatedVideoText?: ITitle;
  showImgOnMobile?: boolean;
  backBtnBg?: string;
  backBtnColor?: string;
  backText?: IText;
  videoCta?: IButton;
}

export const GenericBanner = ({
  ooRate,
  invRate,
  textBoxWidth = 50,
  eyebrow,
  image,
  imageMobile,
  title,
  text,
  ratesVisible,
  rateType = 'OO',
  ratesAlignmentMobile = TextAlignments.LEFT,
  ratesAlignmentDesktop = TextAlignments.LEFT,
  mode,
  backgroundColor,
  backgroundImageMobile,
  backgroundImageDesktop,
  backgroundSizeMobile = 'auto',
  backgroundRepeatMobile = '',
  backgroundPositionMobile = 'center',
  backgroundSizeDesktop = 'auto',
  backgroundRepeatDesktop = '',
  backgroundPositionDesktop = 'center',
  onProduct = false,
  ctaVisible = true,
  ctaAlignment = 'left',
  ctaType = 'funnel',
  cta,
  ctaFunnel,
  trustPilotVisible = true,
  backgroundDesktop = '',
  backgroundMobile = '',
  isVideo = false,
  videoModal,
  relatedVideoText,
  backBtnBg,
  backBtnColor,
  backText,
  showImgOnMobile = false,
  videoCta,
}: IGenericBanner) => {
  let bgPropertyDesktop = '';
  let bgPropertyMobile = '';

  if (backgroundDesktop && backgroundDesktop.length) {
    bgPropertyDesktop = `background : ${backgroundDesktop};`;
  }

  if (backgroundMobile && backgroundMobile.length) {
    bgPropertyMobile = `background : ${backgroundMobile};`;
  }

  const [active, setActive] = useState<boolean>(false);
  const [activeVid, setActiveVid] = useState<number>(0);
  // this always starts from 1 as it's the next video
  const [activeRelatedVid, setActiveRelatedVid] = useState<number>(1);

  return (
    <>
      <style media="all and (max-width: 991px)">
        {`
      html body .mobile-banner-bg  {
          background-color: ${backgroundColor};
          background-image: url(${backgroundImageMobile?.url ? backgroundImageMobile?.url : ''});
          background-size: ${backgroundSizeMobile};
          background-repeat: ${backgroundRepeatMobile};
          background-position: ${backgroundPositionMobile};
          ${bgPropertyMobile}
        }
    `}
      </style>
      <style media="all and (min-width: 992px)">
        {`
            html body .desktop-banner-bg {
                background-color: ${backgroundColor};
                background-image: url(${backgroundImageDesktop?.url ? backgroundImageDesktop?.url : ''});
                background-size: ${backgroundSizeDesktop};
                background-repeat: ${backgroundRepeatDesktop};
                background-position: ${backgroundPositionDesktop};
                ${bgPropertyDesktop}

              }
            html body .banner-text-box {
              width:${textBoxWidth}%!important;
           }
        `}
      </style>

      <div
        className={`${styles.root} mobile-banner-bg desktop-banner-bg ${
          onProduct ? styles.fixedHeight : styles.variableHeight
        }`}
      >
        <div className={`${styles.container} ${ratesVisible ? styles.noPaddingTop : styles.paddingTop}`}>
          {ratesVisible && (
            <div className={styles.rates}>
              {(rateType === 'OO' || rateType === undefined) && ooRate && (
                <Rate
                  rate1Text="Owner Occupied"
                  rate1Rate={Number((parseFloat(ooRate) * 100).toFixed(2))}
                  mode={mode}
                  desktopAlignment={ratesAlignmentMobile}
                  mobileAlignment={ratesAlignmentDesktop}
                />
              )}
              {rateType === 'INV' && invRate && (
                <Rate
                  rate1Text="Investor"
                  rate1Rate={Number((parseFloat(invRate) * 100).toFixed(2))}
                  mode={mode}
                  desktopAlignment={ratesAlignmentMobile}
                  mobileAlignment={ratesAlignmentDesktop}
                />
              )}
              {rateType === 'OO_AND_INV' && ooRate && invRate && (
                <Rate
                  rate1Text="Owner Occupied"
                  rate1Rate={Number((parseFloat(ooRate) * 100).toFixed(2))}
                  rate2Text="Investor"
                  rate2Rate={Number((parseFloat(invRate) * 100).toFixed(2))}
                  mode={mode}
                  desktopAlignment={ratesAlignmentMobile}
                  mobileAlignment={ratesAlignmentDesktop}
                />
              )}
            </div>
          )}
          <div className={`${styles.block} banner-text-box`}>
            {eyebrow && <EyeBrow {...eyebrow} marginBottom={TextMarginBottom.SMALL} />}
            <div className={styles.head}>
              <Title {...title} size={HeadingSizes.EXTRALARGE} marginBottom={TextMarginBottom.SMALL} isBanner />
            </div>
            <div>
              <Title {...text} size={HeadingSizes.MEDIUM} isBanner />
            </div>

            {ctaVisible && (
              <div className={`${styles[`cta-${ctaAlignment}`]}`}>
                {ctaType === CTAType.DEFAULT && <Button {...cta} />}
                {ctaType === CTAType.FUNNEL && !ctaFunnel && <FunnelButton>Get Started</FunnelButton>}
                {ctaType === CTAType.FUNNEL && ctaFunnel && <FunnelButton {...ctaFunnel} />}
              </div>
            )}
          </div>
          {!isVideo && (
            <div className={`${showImgOnMobile ? styles.visible : styles.img}`}>
              {image && image.width && image.height && (
                <img
                  src={responsiveSrcGenerator(image.url, image.width, image.height)}
                  srcSet={srcSetGenerator(image.url, image.width, image.height)}
                  width={Math.round(image.width / 2)}
                  height={Math.round(image.height / 2)}
                  style={{ objectFit: 'cover' }}
                  className={styles.imageDesktop}
                  alt={image ? image.title : ''}
                />
              )}
              {imageMobile && imageMobile.width && imageMobile.height && (
                <img
                  src={responsiveSrcGenerator(imageMobile.url, imageMobile.width, imageMobile.height)}
                  srcSet={srcSetGenerator(imageMobile.url, imageMobile.width, imageMobile.height)}
                  width={Math.round(imageMobile.width / 2)}
                  height={Math.round(imageMobile.height / 2)}
                  style={{ objectFit: 'cover' }}
                  className={styles.imageMobile}
                  alt={imageMobile ? imageMobile.title : ''}
                />
              )}
            </div>
          )}
          {isVideo && (
            <div className={`${showImgOnMobile ? styles.visible : styles.img}`} onClick={() => setActive(true)}>
              {image && image.width && image.height && (
                <img
                  src={responsiveSrcGenerator(image.url, image.width, image.height)}
                  srcSet={srcSetGenerator(image.url, image.width, image.height)}
                  width={Math.round(image.width / 2)}
                  height={Math.round(image.height / 2)}
                  style={{ objectFit: 'cover' }}
                  className={styles.imageDesktop}
                  alt={image ? image.title : ''}
                />
              )}
              {imageMobile && imageMobile.width && imageMobile.height && (
                <img
                  src={responsiveSrcGenerator(imageMobile.url, imageMobile.width, imageMobile.height)}
                  srcSet={srcSetGenerator(imageMobile.url, imageMobile.width, imageMobile.height)}
                  width={Math.round(imageMobile.width / 2)}
                  height={Math.round(imageMobile.height / 2)}
                  style={{ objectFit: 'cover' }}
                  className={styles.imageMobile}
                  alt={imageMobile ? imageMobile.title : ''}
                />
              )}
            </div>
          )}
          {active
            && videoModal ? videoModal.map((item, index) => (activeVid === index ? (
              <VideoModal
                {...item}
                anchorId={item.anchorId}
                key={index}
                cta={videoCta}
                backText={backText}
                handleClick={setActive}
                activeRelatedVid={activeRelatedVid}
                handleActiveVid={setActiveVid}
                activeVid={index}
                backBtnBg={backBtnBg}
                backBtnColor={backBtnColor}
                handleActiveRelatedVid={setActiveRelatedVid}
                videoListLength={videoModal.length}
                relatedVideoText={relatedVideoText}
                backgroundImage={
                          index + 1 >= videoModal.length
                            ? videoModal[0].backgroundImage
                            : videoModal[index + 1].backgroundImage
                        }
              />
            ) : null))
            : null}

          {trustPilotVisible && (
            <>
              <div className={styles.mobileOnly}>
                <div className={styles.trustpilot}>
                  <TrustBox mode={mode} />
                </div>
              </div>
              <div className={styles.desktopOnly}>
                <div className={styles.trustpilot}>
                  <TrustBox mode={mode} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
