import React, { useState } from 'react';
import { GenericBanner, IGenericBanner } from '@/components/GenericBanner';
import { Mode } from '@/constants/Mode';
import { ColumnSizes } from '@/constants/ColumnSizes';
import { CTAType } from '@/constants/CTAType';
import { TabTypes } from '@/constants/TabTypes';
import { ContentDisplayModes } from '@/constants/ContentDisplayModes';
import { WrapOrders } from '@/constants/WrapOrders';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './ProductBanner.module.scss';
import { Container } from './Container';
import { Row } from './Row';
import { Column } from './Column';
import { EyeBrow, IEyebrow } from './EyeBrow';
import { FunnelButton } from './FunnelButton';
import { Title, ITitle } from './Title';
import { Text, IText } from './Text';
import { IButton, Button } from './Button';
import { ProductTabVertical } from './ProductTabVertical';
import { ContentBlock } from './ContentBlock';
import { Elevations } from '../constants/Elevations';
import { TabBar } from './TabBar';
import { IPowerUp, PowerUp } from './PowerUp';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

interface ITabContent {
  mode?: Mode | string;
  bgColor?: string;
  tabName: string;
  eyebrow?: IEyebrow;
  contentTitle?: ITitle;
  contentText?: IText;
  cta?: IButton;
  ctaType?: CTAType | string;
  ctaVisible?: boolean;
  emoji?: string;
  listCta?: IButton;
  listCtaType?: CTAType | string;
  listCtaVisible?: boolean;
  displayMode: string | ContentDisplayModes;
  listTitle?: ITitle;
  listText?: IText;
  list: string[];
  desktopImage?: {
    url: string;
    title: string;
    width: number;
    height: number;
  };
  mobileImage?: {
    url: string;
    title: string;
    width: number;
    height: number;
  };
  mobileContentDirection?: WrapOrders | string;
  desktopContentDirection?: WrapOrders | string;
}

interface ITab {
  bgColor?: string;
  name: string;
  icon: string;
  contentCollection: {
    items: ITabContent[];
  };
  eyebrow?: IEyebrow;
  title?: ITitle;
  text?: IText;
  tabMode?: string;
  cta?: IButton;
  ctaType?: CTAType | string;
  ctaVisible?: boolean;
  desktopControlsOrder?: WrapOrders | string;
  mobileControlsOrder?: WrapOrders | string;
  powerUp: IPowerUp;
}
interface IProductBanner {
  ooRate?: string;
  invRate?: string;
  powerUpOORate?: string;
  powerUpINVRate: string;
  mode: Mode | string;
  tabs: ITab[];
  banner: IGenericBanner;
  theme: string;
}

export const ProductBanner = ({
  banner,
  ooRate,
  invRate,
  tabs,
  mode = Mode.DARK,
  powerUpOORate,
  powerUpINVRate,
  theme,
}: IProductBanner) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [activeContentTab, setActiveContentTab] = useState<number>(0);
  const active = tabs[activeTab];
  const content = tabs[activeTab]?.contentCollection.items[activeContentTab];
  if (active) {
    return (
      <div className={`${styles.root} ${styles[mode]}`}>
        <GenericBanner {...banner} onProduct ooRate={ooRate} invRate={invRate} />
        <div className={styles.seperator} />
        <div className={styles.tabs}>
          <Swiper
            breakpoints={{
              320: {
                slidesPerView: 'auto',
                spaceBetween: 32,
              },
              992: {
                slidesPerView: 'auto',
                spaceBetween: 48,
              },
            }}
            centerInsufficientSlides
            threshold={16}
            className={`${styles.swiper}`}
            slideToClickedSlide
          >
            {tabs.map((tab, index) => (
              <SwiperSlide key={index} className={styles.swiperSlide}>
                <div
                  className={`${styles.tab} ${index === activeTab ? styles.active : styles.inactive}`}
                  onClick={() => {
                    setActiveTab(index);
                    setActiveContentTab(0);
                  }}
                >
                  <span className={styles.icon}>{tab.icon}</span>
                  {tab.name}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={styles.content}>
          {active.tabMode === 'powerup' && (
            <PowerUp
              basicsOORate={ooRate}
              basicsINVRate={invRate}
              powerUpOORate={powerUpOORate}
              powerUpINVRate={powerUpINVRate}
              theme={theme}
              {...active.powerUp}
            />
          )}
          {active.tabMode === 'horizontal-tabs' && (
            <Container>
              <div className={styles.tabRoot}>
                <Row xxsWrap={active.mobileControlsOrder} lgWrap={active.desktopControlsOrder}>
                  <Column xxs={ColumnSizes.FULL} lg={ColumnSizes.ONE_THIRD}>
                    <div
                      className={`${styles.controlSection} ${
                        active.desktopControlsOrder === WrapOrders.ROW ? styles.marginRight : styles.marginLeft
                      }`}
                      style={{ backgroundColor: active?.bgColor !== null ? active.bgColor : 'white' }}
                    >
                      <div className={styles.textSection}>
                        {active.eyebrow && <EyeBrow {...active.eyebrow} />}
                        <div className={styles.verticalTabWrapper}>
                          <TabBar
                            mode={mode === Mode.DARK ? Mode.LIGHT : Mode.DARK}
                            type={TabTypes.BUTTON}
                            setActive={setActiveContentTab}
                            active={activeContentTab}
                            tabs={Array.from(active.contentCollection.items, (tab) => tab.tabName)}
                            stretch
                          />
                        </div>
                        {active.title && <Title {...active.title} />}
                        {active.text && <Text {...active.text} />}
                      </div>
                      {active.ctaVisible && (
                        <div className={styles.cta}>
                          {active.ctaType === CTAType.DEFAULT && <Button {...active.cta} />}
                          {active.ctaType === CTAType.FUNNEL && <FunnelButton>Get Started</FunnelButton>}
                        </div>
                      )}
                    </div>
                  </Column>
                  <Column xxs={ColumnSizes.FULL} lg={ColumnSizes.TWO_THIRDS}>
                    <div
                      className={styles.contentSection}
                      style={{ backgroundColor: content?.bgColor !== null ? content.bgColor : 'white' }}
                    >
                      <ContentBlock
                        eyebrow={content.eyebrow}
                        displayMode={content.displayMode}
                        desktopImage={content.desktopImage}
                        mobileImage={content.mobileImage}
                        elevation={Elevations.NO_ELEVATION}
                        mode={content.mode}
                        title={content.contentTitle}
                        text={content.contentText}
                        cta={content.cta}
                        ctaType={content.ctaType}
                        ctaVisible={content.ctaVisible}
                        listTitle={content.listTitle}
                        listText={content.listText}
                        list={content.list}
                        listCta={content.listCta}
                        listCtaType={content.listCtaType}
                        listCtaVisible={content.listCtaVisible}
                        mobileContentDirection={content.mobileContentDirection}
                        desktopContentDirection={content.desktopContentDirection}
                      />
                    </div>
                  </Column>
                </Row>
              </div>
            </Container>
          )}
          {active.tabMode === 'vertical-tabs' && (
            <Container>
              <div className={styles.tabRoot}>
                <Row xxsWrap={active.mobileControlsOrder} lgWrap={active.desktopControlsOrder}>
                  <Column xxs={ColumnSizes.FULL} lg={ColumnSizes.ONE_THIRD}>
                    <div
                      className={`${styles.controlSection} ${
                        active.desktopControlsOrder === WrapOrders.ROW ? styles.marginRight : styles.marginLeft
                      }`}
                      style={{ backgroundColor: active?.bgColor !== null ? active.bgColor : 'white' }}
                    >
                      <div className={styles.textSection}>
                        {active.eyebrow && <EyeBrow {...active.eyebrow} />}
                        {active.title && <Title {...active.title} />}
                        {active.text && <Text {...active.text} />}
                        {' '}
                        <div className={styles.tabSelector}>
                          {active?.contentCollection.items?.map((tab, index) => (
                            <ProductTabVertical
                              emoji={tab.emoji}
                              key={index}
                              text={tab.tabName}
                              mode={mode}
                              active={activeContentTab === index}
                              handleClick={() => setActiveContentTab(index)}
                            />
                          ))}
                        </div>
                      </div>
                      {active.ctaVisible && (
                        <div className={styles.cta}>
                          {active.ctaType === CTAType.DEFAULT && <Button {...active.cta} />}
                          {active.ctaType === CTAType.FUNNEL && <FunnelButton>Get Started</FunnelButton>}
                        </div>
                      )}
                    </div>
                  </Column>
                  <Column xxs={ColumnSizes.FULL} lg={ColumnSizes.TWO_THIRDS}>
                    <div
                      className={styles.contentSection}
                      style={{ backgroundColor: content?.bgColor !== null ? content.bgColor : 'white' }}
                    >
                      <ContentBlock
                        eyebrow={content.eyebrow}
                        displayMode={content.displayMode}
                        desktopImage={content.desktopImage}
                        mobileImage={content.mobileImage}
                        elevation={Elevations.NO_ELEVATION}
                        mode={content.mode}
                        title={content.contentTitle}
                        text={content.contentText}
                        cta={content.cta}
                        ctaType={content.ctaType}
                        ctaVisible={content.ctaVisible}
                        listTitle={content.listTitle}
                        listText={content.listText}
                        list={content.list}
                        listCta={content.listCta}
                        listCtaType={content.listCtaType}
                        listCtaVisible={content.listCtaVisible}
                        mobileContentDirection={content.mobileContentDirection}
                        desktopContentDirection={content.desktopContentDirection}
                      />
                    </div>
                  </Column>
                </Row>
              </div>
            </Container>
          )}
          {tabs[activeTab].tabMode === 'text-block' && (
            <Container>
              <div className={styles.tabRoot}>
                <Row xxsWrap={active.mobileControlsOrder} lgWrap={active.desktopControlsOrder}>
                  <Column xxs={ColumnSizes.FULL} lg={ColumnSizes.ONE_THIRD}>
                    <div
                      className={`${styles.controlSection} ${
                        active.desktopControlsOrder === WrapOrders.ROW ? styles.marginRight : styles.marginLeft
                      }`}
                      style={{ backgroundColor: active?.bgColor !== null ? active.bgColor : 'white' }}
                    >
                      <div className={styles.textSection}>
                        {active.eyebrow && <EyeBrow {...active.eyebrow} />}
                        {active.title && <Title {...active.title} />}
                        {active.text && <Text {...active.text} />}
                        {' '}
                      </div>
                      {active.ctaVisible && (
                        <div className={styles.cta}>
                          {active.ctaType === CTAType.DEFAULT && <Button {...active.cta} />}
                          {active.ctaType === CTAType.FUNNEL && <FunnelButton>Get Started</FunnelButton>}
                        </div>
                      )}
                    </div>
                  </Column>
                  <Column xxs={ColumnSizes.FULL} lg={ColumnSizes.TWO_THIRDS}>
                    <div
                      className={styles.contentSection}
                      style={{ backgroundColor: content?.bgColor !== null ? content.bgColor : 'white' }}
                    >
                      <ContentBlock
                        eyebrow={content.eyebrow}
                        displayMode={content.displayMode}
                        desktopImage={content.desktopImage}
                        mobileImage={content.mobileImage}
                        elevation={Elevations.NO_ELEVATION}
                        mode={content.mode}
                        title={content.contentTitle}
                        text={content.contentText}
                        cta={content.cta}
                        ctaType={content.ctaType}
                        ctaVisible={content.ctaVisible}
                        listTitle={content.listTitle}
                        listText={content.listText}
                        list={content.list}
                        listCta={content.listCta}
                        listCtaType={content.listCtaType}
                        listCtaVisible={content.listCtaVisible}
                        mobileContentDirection={content.mobileContentDirection}
                        desktopContentDirection={content.desktopContentDirection}
                      />
                    </div>
                  </Column>
                </Row>
              </div>
            </Container>
          )}
        </div>
      </div>
    );
  }
  return <h1 style={{ textAlign: 'center' }}>Banner Content not filled out Correctly</h1>;
};
