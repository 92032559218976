import React from 'react';
import { Themes } from '../constants/Themes';

export interface IText {
  children?: React.ReactNode;
  theme?: Themes | string;
}

export const ThemeProvider = ({ children, theme = Themes.DEFAULT }: IText) => (
  <>
    <link rel="stylesheet" type="text/css" href={`/next-assets/themes/${theme}.css`} />
    {children}
  </>
);
