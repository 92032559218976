import React, { useEffect, useState } from 'react';
import { fetchGraphQL } from '@/queries/fetchGraphQL';
import { RouteQuery } from '@/queries/RouteQuery';
import { GetStaticPaths, GetStaticProps } from 'next';
import { Navigation } from '@/components/Navigation';
import { Footer } from '@/components/Footer';
import { GenericBanner } from '@/components/GenericBanner';
import { BlockRenderer } from '@/components/BlockRenderer';
import { Legal } from '@/components/Legal';
import { ProductBanner } from '@/components/ProductBanner';
import { Mode } from '@/components/Mode';
import { ThemeProvider } from '@/components/ThemeProvider';
import { HomepageContentQuery } from '@/queries/PageContentQuery';
import { useRouter } from 'next/router';
import { getCachedRates } from '../logic/getCachedRates';
import { processMarkdown } from './index';
import { SEO } from '../components/SEO';
import type { NextPageWithLayout, Page } from '../types/layoutExtensions';
import { useScrollAnchorIntoView } from '../utilities/useScrollAnchorIntoView';

const GenericPage: NextPageWithLayout = (page) => {
  useScrollAnchorIntoView();

  const { rates, pageCollection } = page;

  const router = useRouter();
  const isPreview = router.asPath.split('?preview=')[1] === 'true' || false;
  const [data, setData] = useState<Page | null>(null);
  useEffect(() => {
    if (router.asPath === '/start') {
      window.sessionStorage.removeItem('ATHENA_ACTIVATE');
      window.sessionStorage.removeItem('ATHENA_APPLY');
    }
    const queryData = async (isPreviewMode: boolean) => {
      const { data: newData }: any = await fetchGraphQL(HomepageContentQuery(router.asPath.split('?preview=')[0]), isPreviewMode);
      processMarkdown(newData.pageCollection.items[0]);
      setData(newData);
    };
    if ((process.env.NEXT_PUBLIC_ENV === 'release' || process.env.NEXT_PUBLIC_ENV === 'develop') && isPreview) {
      queryData(isPreview);
    }
  }, [isPreview, router.asPath]);

  const lowestOORate = rates['direct-basics'].VARIABLE_RATE.PRINCIPAL_AND_INTEREST.OWNER_OCCUPIED['0-50'].rate;
  const lowestInvRate = rates['direct-basics'].VARIABLE_RATE.PRINCIPAL_AND_INTEREST.INVESTMENT['0-50'].rate;
  const powerUpOORate = rates['direct-extras'].VARIABLE_RATE.PRINCIPAL_AND_INTEREST.OWNER_OCCUPIED['0-50'].rate;
  const powerUpINVRate = rates['direct-extras'].VARIABLE_RATE.PRINCIPAL_AND_INTEREST.INVESTMENT['0-50'].rate;
  let {
    banner, navigationMode, contentCollection, legal, theme,
  } = pageCollection.items[0];

  if (data !== null) {
    banner = data.pageCollection.items[0].banner;
    navigationMode = data.pageCollection.items[0].navigationMode;
    contentCollection = data.pageCollection.items[0].contentCollection;
    legal = data.pageCollection.items[0].legal;
    theme = data.pageCollection.items[0].theme;
  }
  return (
    <ThemeProvider theme={theme}>
      <Navigation mode={navigationMode as Mode} />
      <main id="skip-to-content" style={{ outline: 'none' }} tabIndex={-1}>
        {banner && banner.__typename === 'GenericBanner' && (
          <GenericBanner
            {...banner}
            ooRate={lowestOORate.toString()}
            invRate={lowestInvRate.toString()}
            videoModal={banner.videoModalCollection.items}
          />
        )}
        {banner && banner.__typename === 'ProductBanner' && (
          <ProductBanner
            ooRate={lowestOORate.toString()}
            invRate={lowestInvRate.toString()}
            powerUpOORate={powerUpOORate.toString()}
            powerUpINVRate={powerUpINVRate.toString()}
            banner={banner.banner}
            mode={Mode.DARK}
            tabs={banner.tabsCollection.items}
            theme={theme}
          />
        )}

        {contentCollection?.items.map((contentBlock, index) => (
          <BlockRenderer {...contentBlock} key={index} rates={rates} />
        ))}
        {legal && legal.legalHeading && (
          <Legal
            legalHeading={legal.legalHeading}
            legalComparisonBlock={legal.legalComparisonBlock}
            accordians={legal.accordiansCollection.items[0]}
          />
        )}
      </main>
      <Footer />
    </ThemeProvider>
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  const { data }: any = await fetchGraphQL(RouteQuery('"generic","product"'), false);
  /* Manually exclude home owners & investors buying/refi pages */
  const excludedPaths = [
    '/home-owners/buying',
    '/home-owners/refinancing',
    '/investors/buying',
    '/investors/refinancing',
  ];
  const allowedPaths = data?.pageCollection?.items?.filter((item: any) => !excludedPaths.includes(item.url));

  return {
    paths: allowedPaths?.map(({ url }: { url: string[] }) => `${url}`),
    fallback: false,
  };
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
  let page;
  if (params && params.url && params.url.constructor === Array) {
    let url = '';
    params.url.forEach((item) => {
      url += `/${item}`;
    });
    const { data }: any = await fetchGraphQL(HomepageContentQuery(url), false);
    page = data;
    page.url = url;
    page.rates = await getCachedRates();
  }

  await processMarkdown(page.pageCollection.items[0]);

  return {
    props: page,
  };
};

GenericPage.getLayout = (page: React.ReactElement) => {
  const {
    seoTitle, seoDescription, ldJsonSchema, ogImage,
  } = page.props.children[1].props.pageCollection.items[0];
  const { url } = page.props.children[1].props;
  return (
    <>
      <SEO
        {...{ seoTitle, seoDescription, ldJsonSchema }}
        {...(ogImage ? { ogImageUrl: ogImage.url, ogImageWidth: ogImage.width, ogImageHeight: ogImage.height } : {})}
        fullPath={`${url}`}
      />
      <div className="page">{page}</div>
    </>
  );
};

export default GenericPage;
