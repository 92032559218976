import React from 'react';
import Link from 'next/link';

import { TextMarginBottom } from '@/constants/TextMarginBottom';
import { TextAlignments } from '@/constants/TextAlignments';

import { Mode } from '@/constants/Mode';
import { HeadingSizes } from '@/constants/HeadingSizes';
import { TextSizes } from '@/constants/TextSizes';
import styles from './Rate.module.scss';
import { Title } from './Title';
import { Text } from './Text';

interface IRate {
  rate1Text: string;
  rate1Rate: number;
  rate2Text?: string;
  rate2Rate?: number;
  mode: Mode | string;
  desktopAlignment?: TextAlignments | string;
  mobileAlignment?: TextAlignments | string;
}

export const Rate = ({
  mode = Mode.DARK,
  rate1Text,
  rate1Rate,
  rate2Text,
  rate2Rate,
  desktopAlignment = TextAlignments.LEFT,
  mobileAlignment = TextAlignments.LEFT,
}: IRate) => (
  <Link href="/features/rates-no-fees" title="See our Rates and No Fees">
    <div className={styles.title}>
      <Text
        desktopAlignment={desktopAlignment}
        mobileAlignment={mobileAlignment}
        mode={mode}
        marginBottom={TextMarginBottom.NONE}
        style={{ color: mode === Mode.DARK ? 'var(--purple-light)' : 'var(--bright-purple)' }}
      >
        <p>
          <strong>RATES FROM</strong>
        </p>
      </Text>
    </div>
    <div
      className={`${styles.rates} ${styles[`desktop-${desktopAlignment}`]} ${styles[`mobile-${mobileAlignment}`]}`}
    >
      <div className={styles.rate}>
        <Text
          desktopAlignment={desktopAlignment}
          mobileAlignment={mobileAlignment}
          mode={mode}
          marginBottom={TextMarginBottom.NONE}
        >
          <p>
            <strong>
              {' '}
              {rate1Text}
            </strong>
          </p>
        </Text>
        <div className={styles.number}>
          <Title
            desktopAlignment={desktopAlignment}
            mobileAlignment={mobileAlignment}
            mode={mode}
            size={HeadingSizes.MEDIUM}
            marginBottom={TextMarginBottom.NONE}
          >
            <p>{`${rate1Rate}%`}</p>
          </Title>
        </div>
        <Text
          desktopAlignment={desktopAlignment}
          mobileAlignment={mobileAlignment}
          mode={mode}
          marginBottom={TextMarginBottom.NONE}
          style={{ color: mode === Mode.DARK ? 'var(--grey-bright)' : 'var(--deep-purple)', opacity: '0.7' }}
          size={TextSizes.SMALL}
        >
          <p>
            <strong> Variable & Comparison^</strong>
          </p>
        </Text>
        {' '}
      </div>

      {rate2Text && rate2Rate && (
      <>
        <div className={styles.divider} />
        <div className={styles.rate}>
          <Text
            desktopAlignment={TextAlignments.LEFT}
            mobileAlignment={TextAlignments.CENTER}
            mode={mode}
            marginBottom={TextMarginBottom.NONE}
          >
            <p>
              <strong>
                {' '}
                {rate2Text}
              </strong>
            </p>
          </Text>
          <div className={styles.number}>
            <Title
              desktopAlignment={TextAlignments.LEFT}
              mobileAlignment={TextAlignments.CENTER}
              mode={mode}
              size={HeadingSizes.MEDIUM}
              marginBottom={TextMarginBottom.NONE}
            >
              <p>
                <strong>
                  {' '}
                  {`${rate2Rate}%`}
                </strong>
              </p>
            </Title>
          </div>
          <Text
            desktopAlignment={TextAlignments.LEFT}
            mobileAlignment={TextAlignments.CENTER}
            mode={mode}
            marginBottom={TextMarginBottom.NONE}
            style={{ color: mode === Mode.DARK ? 'var(--grey-bright)' : 'var(--deep-purple)', opacity: '0.7' }}
            size={TextSizes.SMALL}
          >
            <p>
              <strong> Variable & Comparison^</strong>
            </p>
          </Text>
        </div>
      </>
      )}
    </div>
  </Link>
);
