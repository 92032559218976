import React, { useState } from 'react';
import { TextAlignments } from '@/constants/TextAlignments';
import { TextMarginBottom } from '@/constants/TextMarginBottom';
import { Swiper, SwiperSlide } from 'swiper/react';
import dynamic from 'next/dynamic';
import { Autoplay } from 'swiper';
import styles from './PowerUp.module.scss';
import { Container } from './Container';
import { Title } from './Title';
import { Text } from './Text';
import { Mode } from './Mode';
import { Button, IButton } from './Button';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

const StraightUpDesktop = dynamic(() => import('../content/logos').then((comp) => comp.StraightUpDesktop), {
  ssr: false,
});

const PowerUpDesktop = dynamic(() => import('../content/logos').then((comp) => comp.PowerUpDesktop), {
  ssr: false,
});

const StraightUpMobile = dynamic(() => import('../content/logos').then((comp) => comp.StraightUpMobile), {
  ssr: false,
});

const PowerUpMobile = dynamic(() => import('../content/logos').then((comp) => comp.PowerUpMobile), {
  ssr: false,
});
export interface IPowerUp {
  basicFeatures: string[];
  extrasFeatures: string[];
  basicTitle: string;
  basicSubtitle: string;
  basicQuestion: string;
  basicCtaText: string;
  featuresTitle: string;
  basicCta: IButton;
  extrasTitle: string;
  extrasSubtitle: string;
  extrasQuestion: string;
  extrasCtaText: string;
  extrasCta: IButton;
}

interface IPowerUpWithRates extends IPowerUp {
  powerUpOORate?: string;
  basicsOORate?: string;
  basicsINVRate?: string;
  powerUpINVRate?: string;
  theme?: string;
}

const desktopConfig: any = {
  slidesPerView: 'auto',
  spaceBetween: 8,
  allowTouchMove: false,
  centerInsufficientSlides: true,
};

const mobileConfig: any = {
  speed: 5000,
  slidesPerView: 'auto',
  spaceBetween: 8,
  centeredSlides: true,
  allowTouchMove: false,
  centerInsufficientSlides: true,
  autoplay: {
    delay: 1,
  },
  loop: true,
};

export const PowerUp = ({
  basicFeatures,
  basicSubtitle,
  basicQuestion,
  basicCtaText,
  featuresTitle,
  extrasFeatures,
  basicCta,
  extrasSubtitle,
  extrasQuestion,
  extrasCtaText,
  extrasCta,
  basicsOORate,
  powerUpOORate,
  basicsINVRate,
  powerUpINVRate,
  theme,
}: IPowerUpWithRates) => {
  const [powerUp, setPowerUp] = useState<boolean>(false);
  return (
    <Container>
      <div className={`${styles.powerBlock}  ${powerUp ? styles.powerUpOn : styles.straightUpOn}`}>
        <div className={styles.leftSection}>
          <div className={styles.leftContent}>
            <div className={styles.leftTopBlock}>
              <Title
                mobileAlignment={TextAlignments.CENTER}
                desktopAlignment={TextAlignments.CENTER}
                marginBottom={TextMarginBottom.SMALL}
                size="powerup"
              >
                <div className={styles.titles}>
                  <div className={styles.straightUpText}>
                    <div className={styles.desktop}>
                      <StraightUpDesktop />
                    </div>
                    <div className={styles.mobile}>
                      <StraightUpMobile />
                    </div>
                  </div>
                  <div className={styles.powerUpText}>
                    <div className={styles.desktop}>
                      <PowerUpDesktop />
                    </div>
                    <div className={styles.mobile}>
                      <PowerUpMobile />
                    </div>
                  </div>
                </div>
              </Title>
              <Title
                size="sm"
                mobileAlignment={TextAlignments.CENTER}
                desktopAlignment={TextAlignments.CENTER}
                marginBottom={TextMarginBottom.LARGE}
              >
                <p className={styles.straightUpText}>
                  <span>
                    {' '}
                    {basicSubtitle}
                  </span>
                </p>
                <p className={styles.powerUpText}>
                  <span>{extrasSubtitle}</span>
                </p>
              </Title>
              <div className={styles.mobileCta}>
                {powerUp === false && <Button {...basicCta} mode={Mode.LIGHT} />}
                {powerUp && <Button {...extrasCta} mode={Mode.LIGHT} />}
              </div>
            </div>
            <div className={styles.leftBottomBlock}>
              <Title size="s" desktopAlignment={TextAlignments.CENTER} marginBottom={TextMarginBottom.TINY}>
                <p className={styles.straightUpText}>
                  <span>
                    {' '}
                    {basicQuestion}
                  </span>
                </p>
                <p className={styles.powerUpText}>
                  <span>{extrasQuestion}</span>
                </p>
              </Title>
              <Title size="sm" desktopAlignment={TextAlignments.CENTER} marginBottom={TextMarginBottom.SMALL}>
                <p className={styles.straightUpText}>
                  <span className="purplePinkGradient">{basicCtaText}</span>
                </p>
                <p className={styles.powerUpText}>
                  <span className="pinkBrightGradient">{extrasCtaText}</span>
                </p>
              </Title>
              <div
                className={`${styles.powerUpSlider} ${powerUp ? styles.powerUpMode : styles.straightUpMode}`}
                onClick={() => {
                  setPowerUp(!powerUp);
                }}
              >
                <div
                  className={styles.powerUp}
                  onClick={() => {
                    setPowerUp(!powerUp);
                  }}
                >
                  <svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.6921 27.6422L15.8972 15.4371L9.43569 11.1294L12.3075 0.360138L0.102356 12.5653L6.5639 16.873L3.6921 27.6422Z"
                      fill={powerUp ? '#DE026A' : '#7200CB'}
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.rightSection} ${powerUp ? styles.diagonal : styles.straight}`}>
          <div className={styles.animation} />
          <div className={styles.rightContent}>
            <Title
              mode={Mode.DARK}
              size="m"
              mobileAlignment={TextAlignments.CENTER}
              desktopAlignment={TextAlignments.CENTER}
            >
              <p>{featuresTitle}</p>
            </Title>
            <div className={styles.desktopSwiper}>
              <div className={styles.swiperWrapper}>
                {basicFeatures && (
                  <Swiper modules={[Autoplay]} {...desktopConfig}>
                    {basicFeatures.map((feature: string, index: number) => (
                      <SwiperSlide key={index} className={styles.swiperSlide}>
                        <Text size="s">
                          <strong>{feature}</strong>
                        </Text>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </div>
              <div className={` ${styles.swiperWrapper} ${styles.powerOnlySwiper}`}>
                {extrasFeatures && (
                  <Swiper modules={[Autoplay]} {...desktopConfig}>
                    {extrasFeatures.map((feature: string, index: number) => (
                      <SwiperSlide key={index} className={styles.swiperSlide}>
                        <Text size="s">
                          <strong>{feature}</strong>
                        </Text>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </div>
            </div>
            <div className={styles.mobileSwiper}>
              <div className={styles.swiperWrapper}>
                {basicFeatures && (
                  <Swiper id="topWrapper" modules={[Autoplay]} {...mobileConfig}>
                    {basicFeatures.map((feature: string, index: number) => (
                      <SwiperSlide key={index} className={styles.swiperSlide}>
                        <Text size="s">
                          <strong>{feature}</strong>
                        </Text>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </div>
              <div className={` ${styles.swiperWrapper} ${styles.powerOnlySwiper}`}>
                {extrasFeatures && (
                  <Swiper
                    id="bottomWrapper"
                    modules={[Autoplay]}
                    {...mobileConfig}
                    autoplay={{
                      delay: 1,
                      reverseDirection: true,
                    }}
                  >
                    {extrasFeatures.map((feature: string, index: number) => (
                      <SwiperSlide key={index} className={styles.swiperSlide}>
                        <Text size="s">
                          <strong>{feature}</strong>
                        </Text>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </div>
            </div>
            <div className={` ${styles.rateSection} ${powerUp ? styles.powerUp : styles.straightUp}`}>
              <p className={styles.rate}>
                {theme && (
                  <>
                    {powerUpOORate && powerUpINVRate && (
                      <span className={styles.powerUpRate}>
                        {(parseFloat(theme === 'investment' ? powerUpINVRate : powerUpOORate) * 100).toFixed(2)}
                      </span>
                    )}
                    {basicsOORate && basicsINVRate && (
                      <span className={styles.straightUpRate}>
                        {(parseFloat(theme === 'investment' ? basicsINVRate : basicsOORate) * 100).toFixed(2)}
                      </span>
                    )}
                  </>
                )}

                <span className={styles.percentage}>%</span>
              </p>
              <p className={styles.rateType}>Variable & Comparison^</p>
              <p className={styles.rateTier}>≤50% LVR</p>
            </div>

            <div className={styles.desktopCta}>
              {powerUp === false && <Button {...basicCta} mode={Mode.DARK} />}
              {powerUp && <Button {...extrasCta} mode={Mode.DARK} />}
            </div>

            <div className={styles.mobilePowerUpButton}>
              <Text
                mode={Mode.DARK}
                size="s"
                mobileAlignment={TextAlignments.CENTER}
                marginBottom={TextMarginBottom.TINY}
              >
                <p className={`${styles.straightUpText} purplePinkGradient`}>
                  <span>{basicQuestion}</span>
                </p>
                <p className={styles.powerUpText}>
                  <span>{extrasQuestion}</span>
                </p>
              </Text>
              <Title
                size="sm"
                mode={Mode.DARK}
                mobileAlignment={TextAlignments.CENTER}
                marginBottom={TextMarginBottom.SMALL}
              >
                <p className={styles.straightUpText}>
                  <span>{basicCtaText}</span>
                </p>
                <p className={styles.powerUpText}>
                  <span>{extrasCtaText}</span>
                </p>
              </Title>
              <div
                className={`${styles.powerUpSlider} ${powerUp ? styles.powerUpMode : styles.straightUpMode}`}
                onClick={() => {
                  setPowerUp(!powerUp);
                }}
              >
                <div
                  className={styles.powerUp}
                  onClick={() => {
                    setPowerUp(!powerUp);
                  }}
                >
                  <svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.6921 27.6422L15.8972 15.4371L9.43569 11.1294L12.3075 0.360138L0.102356 12.5653L6.5639 16.873L3.6921 27.6422Z"
                      fill={powerUp ? '#DE026A' : '#7200CB'}
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
