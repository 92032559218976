import React from 'react';
import { Mode } from '@/constants/Mode';
import styles from './ProductTabVertical.module.scss';

export interface ITab {
  emoji?: string;
  text: string;
  mode?: Mode | string;
  active: boolean;
  handleClick: () => void;
}

export const ProductTabVertical = ({
  active, handleClick, text = '', emoji = '', mode = Mode.LIGHT,
}: ITab) => (
  <div
    onClick={() => {
      handleClick();
    }}
    className={`${styles.root} `}
  >
    <div className={`${styles.bg} ${styles[mode]} ${active ? styles.active : ''}`}>
      <div className={styles.textContent}>
        <div className={styles.icon}>{emoji}</div>
        <div className={styles.text}>{text}</div>
      </div>
      <div className={styles.arrow}>
        <svg width="24" height="10" viewBox="0 0 24 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M23.0677 4.9928L18.818 0.757324L17.4061 2.17392L19.2415 4.00313L0.932469 4.00113L0.932251 6.00113L19.2369 6.00313L17.4155 7.83075L18.8321 9.24258L23.0677 4.9928Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  </div>
);
